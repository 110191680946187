export const PROXY_BASE_URL_HEADER = 'x-proxy-target-base'

interface ProxiedConfig {
  baseURL: string
  query?: Record<string, string>
}

interface ApplyProxyConfig {
  proxyBaseUrl: string
}

/**
 * Adjusts a provided client configuration so that it's always proxied regardless of configurations
 */
const applyProxy = (config: ProxiedConfig, { proxyBaseUrl }: ApplyProxyConfig) => mergeDeep(config, {
  baseURL: proxyBaseUrl,
  headers: {
    ...(config.baseURL ? { [PROXY_BASE_URL_HEADER]: config.baseURL } : {})
  }
})

export const applyApiProxy = (config: ProxiedConfig) => applyProxy(
  config,
  { proxyBaseUrl: useRuntimeConfig().public.apiRoutePrefix }
)
