/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type {
  GetFavoritesData,
  GetSavedForLaterData,
  GetSharedFavoritesData,
  RemoveFavoritesItemData,
  WishlistsAddToFavoritesData,
  WishlistsFavoritesItemRequest,
  WishlistsShareFavoritesData,
  WishlistsShareFavoritesPayload,
} from "./data-contracts";

export class Wishlists extends HttpClient {
  static paths = {
    getFavorites: "/wishlists/v2/favorites",
    wishlistsAddToFavorites: "/wishlists/v2/favorites",
    getSavedForLater: "/wishlists/v2/saveForLater",
    getSharedFavorites: "/wishlists/v2/favorites/:favoriteId/share",
    wishlistsShareFavorites: "/wishlists/v2/favorites/:favoriteId/share",
    removeFavoritesItem: "/wishlists/v2/favorites/:favoriteId/items/:itemId",
  };
  static mockDataPaths = {};
  static mockedPaths = {
    getFavorites: false,
    wishlistsAddToFavorites: false,
    getSavedForLater: false,
    getSharedFavorites: false,
    wishlistsShareFavorites: false,
    removeFavoritesItem: false,
  };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "wishlists");
  }

  /**
   * No description
   *
   * @tags Wishlists
   * @name GetFavorites
   * @summary Get Favorites API
   * @request GET:/wishlists/v2/favorites
   */
  getFavorites = <
    ResT = GetFavoritesData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/wishlists/v2/favorites")
        ? computed(() => mockPath("Wishlists", "getFavorites", ``))
        : computed(() => `/wishlists/v2/favorites`),
      method: "GET",
      key: "/wishlists/v2/favorites",
      apiName: "Wishlists.getFavorites",
      ...params,
    });
  /**
   * No description
   *
   * @tags Wishlists
   * @name GetFavorites
   * @summary Get Favorites API
   * @request GET:/wishlists/v2/favorites
   */
  $getFavorites = (requestParams: ApiOptions = {}) =>
    this.$request<GetFavoritesData>({
      path: isMocked("/wishlists/v2/favorites") ? mockPath("Wishlists", "getFavorites", ``) : `/wishlists/v2/favorites`,
      method: "GET",
      apiName: "Wishlists.getFavorites",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Wishlists
   * @name WishlistsAddToFavorites
   * @summary Add to Favorites API
   * @request POST:/wishlists/v2/favorites
   */
  wishlistsAddToFavorites = <
    ResT = WishlistsAddToFavoritesData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: WishlistsFavoritesItemRequest,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/wishlists/v2/favorites")
        ? computed(() => mockPath("Wishlists", "wishlistsAddToFavorites", ``))
        : computed(() => `/wishlists/v2/favorites`),
      method: "POST",
      body: data,
      key: "/wishlists/v2/favorites",
      apiName: "Wishlists.wishlistsAddToFavorites",
      ...params,
    });
  /**
   * No description
   *
   * @tags Wishlists
   * @name WishlistsAddToFavorites
   * @summary Add to Favorites API
   * @request POST:/wishlists/v2/favorites
   */
  $wishlistsAddToFavorites = (data: WishlistsFavoritesItemRequest, requestParams: ApiOptions = {}) =>
    this.$request<WishlistsAddToFavoritesData>({
      path: isMocked("/wishlists/v2/favorites")
        ? mockPath("Wishlists", "wishlistsAddToFavorites", ``)
        : `/wishlists/v2/favorites`,
      method: "POST",
      body: data,
      apiName: "Wishlists.wishlistsAddToFavorites",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Wishlists
   * @name GetSavedForLater
   * @summary Get Saved for Later
   * @request GET:/wishlists/v2/saveForLater
   */
  getSavedForLater = <
    ResT = GetSavedForLaterData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/wishlists/v2/saveForLater")
        ? computed(() => mockPath("Wishlists", "getSavedForLater", ``))
        : computed(() => `/wishlists/v2/saveForLater`),
      method: "GET",
      key: "/wishlists/v2/saveForLater",
      apiName: "Wishlists.getSavedForLater",
      ...params,
    });
  /**
   * No description
   *
   * @tags Wishlists
   * @name GetSavedForLater
   * @summary Get Saved for Later
   * @request GET:/wishlists/v2/saveForLater
   */
  $getSavedForLater = (requestParams: ApiOptions = {}) =>
    this.$request<GetSavedForLaterData>({
      path: isMocked("/wishlists/v2/saveForLater")
        ? mockPath("Wishlists", "getSavedForLater", ``)
        : `/wishlists/v2/saveForLater`,
      method: "GET",
      apiName: "Wishlists.getSavedForLater",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Wishlists
   * @name GetSharedFavorites
   * @summary Get Shared Favorites API
   * @request GET:/wishlists/v2/favorites/{favoriteId}/share
   */
  getSharedFavorites = <
    ResT = GetSharedFavoritesData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    favoriteId: string | Ref<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/wishlists/v2/favorites/:favoriteId/share")
        ? computed(() => mockPath("Wishlists", "getSharedFavorites", `favoriteId:${unref(favoriteId)}`))
        : computed(() => `/wishlists/v2/favorites/${unref(favoriteId)}/share`),
      method: "GET",
      key: "/wishlists/v2/favorites/:favoriteId/share",
      apiName: "Wishlists.getSharedFavorites",
      ...params,
    });
  /**
   * No description
   *
   * @tags Wishlists
   * @name GetSharedFavorites
   * @summary Get Shared Favorites API
   * @request GET:/wishlists/v2/favorites/{favoriteId}/share
   */
  $getSharedFavorites = (favoriteId: string, requestParams: ApiOptions = {}) =>
    this.$request<GetSharedFavoritesData>({
      path: isMocked("/wishlists/v2/favorites/:favoriteId/share")
        ? mockPath("Wishlists", "getSharedFavorites", `favoriteId:${unref(favoriteId)}`)
        : `/wishlists/v2/favorites/${favoriteId}/share`,
      method: "GET",
      apiName: "Wishlists.getSharedFavorites",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Wishlists
   * @name WishlistsShareFavorites
   * @summary Share Favorites API
   * @request POST:/wishlists/v2/favorites/{favoriteId}/share
   */
  wishlistsShareFavorites = <
    ResT = WishlistsShareFavoritesData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    favoriteId: string | Ref<string>,
    data?: WishlistsShareFavoritesPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/wishlists/v2/favorites/:favoriteId/share")
        ? computed(() => mockPath("Wishlists", "wishlistsShareFavorites", `favoriteId:${unref(favoriteId)}`))
        : computed(() => `/wishlists/v2/favorites/${unref(favoriteId)}/share`),
      method: "POST",
      body: data,
      key: "/wishlists/v2/favorites/:favoriteId/share",
      apiName: "Wishlists.wishlistsShareFavorites",
      ...params,
    });
  /**
   * No description
   *
   * @tags Wishlists
   * @name WishlistsShareFavorites
   * @summary Share Favorites API
   * @request POST:/wishlists/v2/favorites/{favoriteId}/share
   */
  $wishlistsShareFavorites = (
    favoriteId: string,
    data?: WishlistsShareFavoritesPayload,
    requestParams: ApiOptions = {},
  ) =>
    this.$request<WishlistsShareFavoritesData>({
      path: isMocked("/wishlists/v2/favorites/:favoriteId/share")
        ? mockPath("Wishlists", "wishlistsShareFavorites", `favoriteId:${unref(favoriteId)}`)
        : `/wishlists/v2/favorites/${favoriteId}/share`,
      method: "POST",
      body: data,
      apiName: "Wishlists.wishlistsShareFavorites",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Wishlists
   * @name RemoveFavoritesItem
   * @summary Remove Favorites Item API
   * @request DELETE:/wishlists/v2/favorites/{favoriteId}/items/{itemId}
   */
  removeFavoritesItem = <
    ResT = RemoveFavoritesItemData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    favoriteId: string | Ref<string>,
    itemId: string | Ref<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/wishlists/v2/favorites/:favoriteId/items/:itemId")
        ? computed(() =>
            mockPath("Wishlists", "removeFavoritesItem", `favoriteId:${unref(favoriteId)}.itemId:${unref(itemId)}`),
          )
        : computed(() => `/wishlists/v2/favorites/${unref(favoriteId)}/items/${unref(itemId)}`),
      method: "DELETE",
      key: "/wishlists/v2/favorites/:favoriteId/items/:itemId",
      apiName: "Wishlists.removeFavoritesItem",
      ...params,
    });
  /**
   * No description
   *
   * @tags Wishlists
   * @name RemoveFavoritesItem
   * @summary Remove Favorites Item API
   * @request DELETE:/wishlists/v2/favorites/{favoriteId}/items/{itemId}
   */
  $removeFavoritesItem = (favoriteId: string, itemId: string, requestParams: ApiOptions = {}) =>
    this.$request<RemoveFavoritesItemData>({
      path: isMocked("/wishlists/v2/favorites/:favoriteId/items/:itemId")
        ? mockPath("Wishlists", "removeFavoritesItem", `favoriteId:${unref(favoriteId)}.itemId:${unref(itemId)}`)
        : `/wishlists/v2/favorites/${favoriteId}/items/${itemId}`,
      method: "DELETE",
      apiName: "Wishlists.removeFavoritesItem",
      ...requestParams,
    });
}
