<template>
  <div v-style:m="containerMargin">
    <h2
      v-if="title"
      v-style:c="titleColor"
      :class="[titleStyleClasses, titleStyle, {
        'text-center': alignment === 'center'
          || titleAlignment === 'center'
          || titlePosition === 'center',
      }]"
    >
      {{ title }}
    </h2>
    <vf-carousel
      ref="carousel"
      :class="[collectionCenter, carousel?.scrollable && carouselClasses]"
      :autoplay="carouselSettings.autoplay"
      :loop="carouselSettings.loop"
      :interval="carouselSettings.interval"
      :style-container="{
        '--p': mobileSlidePadding,
        '--p-md': 0,
      }"
      :class-container="classContainer"
      :class-controls="classControls"
    >
      <div
        v-for="item in items"
        :key="item.id"
        v-style="{ 'max-w': slideMaxWidth, 'p': slidePadding, 'w': slideWidth }"
      >
        <component
          :is="`lazy-cms-${resolveContentComponent(item, mappings)?.component}`"
          :content="item"
          :class="itemClass"
          is-part-of-collection
        />
      </div>
      <template #pagination="{ activeItem }">
        <div v-show="carouselSettings.showIndicators">
          <div v-if="items.length" class="flex justify-center pt-6 space-x-2">
            <div
              v-for="(_, i) in items"
              :key="i"
              class="h-2 w-2 b rounded-full"
              :class="activeItem === i ? 'b-grey-10 bg-grey-10' : 'b-grey-40'"
            />
          </div>
        </div>
      </template>
    </vf-carousel>
  </div>
</template>

<script lang="ts" setup>
import componentTypeConfig from './config'
import mappings from '#content/mappings'
import { resolveContentComponent } from '#content/utils'
import type { CollectionContent } from '#types/components/cms/collection'
import type { Responsive } from '#types/common'

const { content } = defineProps<{ content: CollectionContent<any> }>()

const carousel = ref()
const { breakpoints } = useAppConfig().ds
const { $viewport } = useNuxtApp()
const {
  alignment,
  equalTargetSize,
  title,
  titleAlignment,
  titleColor,
  titlePosition,
  titleStyle
} = content
const { lg, md, sm } = content.breakpoints
const { itemClass, itemsProp, titleStyleClasses, carouselClasses } = componentTypeConfig[content.type!] || {}

const items = (itemsProp ? content[itemsProp] : content.items) || []

const collectionCenter = alignment === 'center' && {
  'lg:justify-center lg:text-center lg:flex': lg?.type === 'carousel',
  'md:justify-center md:text-center md:flex': md?.type === 'carousel',
  '<md:justify-center <md:text-center <md:flex': sm?.type === 'carousel'
}

const getItemWidth = (w?: string) => {
  if (w === 'auto') return `${100 / items.length}%`
  return w || 'auto'
}

const getItemMaxWidth = (maxW?: string) => maxW || 'none'

const slideWidth: Record<keyof Responsive, string> = {
  sm: getItemWidth(getValueForBreakpoint('sm', equalTargetSize) && content.type === 'VfCanvasCTAButtonCollection' ? '12.5rem' : sm?.slideWidth),
  md: getItemWidth(getValueForBreakpoint('md', equalTargetSize) && content.type === 'VfCanvasCTAButtonCollection' ? '12.5rem' : md?.slideWidth),
  lg: getItemWidth(getValueForBreakpoint('lg', equalTargetSize) && content.type === 'VfCanvasCTAButtonCollection' ? '12.5rem' : lg?.slideWidth)
}

const slideMaxWidth: Record<keyof Responsive, string> = {
  sm: getItemMaxWidth(sm?.slideMaxWidth),
  md: getItemMaxWidth(md?.slideMaxWidth),
  lg: getItemMaxWidth(lg?.slideMaxWidth)
}

// Mobile carousel centring
const mobileSlideWidth = Number.parseInt(slideWidth.sm)
const mobileSlidePadding = Number.isNaN(mobileSlideWidth) ? 0 : `0 0 0 ${(100 - mobileSlideWidth) / 2}%`

const marginMap = {
  small: 2,
  medium: 4,
  large: 6
}

const spacingMap = {
  small: '0.5rem',
  medium: '1rem',
  large: '1.5rem'
}

const containerMargin: Record<keyof Responsive, any> = {
  sm: sm?.margin && `0 ${sm.margin}`,
  md: md?.margin && `0 ${md.margin}`,
  lg: lg?.margin && `0 ${lg.margin}`
}

// Create padding for each slide
const slidePadding = Object.keys(breakpoints)
  .reduce((acc, bp) => ({
    ...acc,
    ...(content.breakpoints[bp]?.slidePadding && {
      [bp]: spacingMap[content.breakpoints[bp].slidePadding] || 0
    })
  }), {})

const classControls = ((): string[] => {
  const defaultStyle = 'bg-white p-2 rounded-full shadow-sm mx-4'
  const hiddenClass = {
    sm: '<md:hidden',
    md: '~md:hidden',
    lg: 'lg:hidden'
  }

  return Object.keys(hiddenClass).reduce((acc, bp) => {
    if (content.breakpoints[bp]?.type === 'grid') return [...acc, hiddenClass[bp]]
    // show arrows by default if showArrows is not set
    return [...acc, ...(content.breakpoints[bp]?.showArrows ?? true) ? [] : [hiddenClass[bp]]]
  }, [defaultStyle])
})()

/**
 * Get style classes for carousel containers
 */
const classContainer = [
  // Mobile slides centring
  'scroll-pa-$p',
  'md:scroll-pa-$p-md',
  // Create a negative margin for the container to compensate slides paddings
  sm?.slidePadding ? `-mx-${marginMap[sm.slidePadding]}` : '',
  md?.slidePadding ? `md:-mx-${marginMap[md.slidePadding]}` : '',
  lg?.slidePadding ? `lg:-mx-${marginMap[lg.slidePadding]}` : '',
  // Carousel vs Grid Layout
  {
    '<md:wrap': sm?.type === 'grid',
    '~md:wrap': md?.type === 'grid',
    'lg:wrap': lg?.type === 'grid'
  }
]

const carouselSettings = computed(() => {
  const {
    autoplay,
    autorotateTime = 0,
    loop,
    showIndicators
  } = content.breakpoints[$viewport.breakpoint] || {}

  return {
    autoplay,
    loop,
    showIndicators,
    interval: autorotateTime * 1000,
  }
})
</script>
