/* eslint-disable */

import { mergeDeep } from '#core/utils/object/mergeDeep'
import type { ApiOptions } from '#root/api/clients/http-client'
import { Authentication } from '~/api/clients/authentication/Authentication'
import { Cart } from '~/api/clients/cart/Cart'
import { Consumer } from '~/api/clients/consumer/Consumer'
import { ContactUs } from '~/api/clients/contact-us/ContactUs'
import { Content } from '~/api/clients/content/Content'
import { Internal } from '~/api/clients/internal/Internal'
import { MarketingAuth } from '~/api/clients/marketing-auth/MarketingAuth'
import { Marketing } from '~/api/clients/marketing/Marketing'
import { Decide } from '~/api/clients/monetate/Decide'
import { Products } from '~/api/clients/product/Products'
import { SocialNative } from '~/api/clients/social-native/SocialNative'
import { Stores } from '~/api/clients/stores/Stores'
import { Utilities } from '~/api/clients/utilities/Utilities'
import { Wishlists } from '~/api/clients/wishlists/Wishlists'

export type CanvasApiConfig = {
  base: ApiOptions,
  authentication?: ApiOptions,
  cart?: ApiOptions,
  consumer?: ApiOptions,
  contactUs?: ApiOptions,
  content?: ApiOptions,
  internal?: ApiOptions,
  marketingAuth?: ApiOptions,
  marketing?: ApiOptions,
  decide?: ApiOptions,
  products?: ApiOptions,
  socialNative?: ApiOptions,
  stores?: ApiOptions,
  utilities?: ApiOptions,
  wishlists?: ApiOptions,
}

export const api = (config: CanvasApiConfig) => ({
  authentication: new Authentication(mergeDeep(config.base, config.authentication) as ApiOptions),
  cart: new Cart(mergeDeep(config.base, config.cart) as ApiOptions),
  consumer: new Consumer(mergeDeep(config.base, config.consumer) as ApiOptions),
  contactUs: new ContactUs(mergeDeep(config.base, config.contactUs) as ApiOptions),
  content: new Content(mergeDeep(config.base, config.content) as ApiOptions),
  internal: new Internal(mergeDeep(config.base, config.internal) as ApiOptions),
  marketingAuth: new MarketingAuth(mergeDeep(config.base, config.marketingAuth) as ApiOptions),
  marketing: new Marketing(mergeDeep(config.base, config.marketing) as ApiOptions),
  decide: new Decide(mergeDeep(config.base, config.decide) as ApiOptions),
  products: new Products(mergeDeep(config.base, config.products) as ApiOptions),
  socialNative: new SocialNative(mergeDeep(config.base, config.socialNative) as ApiOptions),
  stores: new Stores(mergeDeep(config.base, config.stores) as ApiOptions),
  utilities: new Utilities(mergeDeep(config.base, config.utilities) as ApiOptions),
  wishlists: new Wishlists(mergeDeep(config.base, config.wishlists) as ApiOptions),
})

export type CanvasApiClient = ReturnType<typeof api>
type CanvasApiClientFactory = (...config: Parameters<typeof api>) => CanvasApiClient

export default api as CanvasApiClientFactory
export const apiPaths = {
  authentication: Authentication.paths,
  cart: Cart.paths,
  consumer: Consumer.paths,
  contactUs: ContactUs.paths,
  content: Content.paths,
  internal: Internal.paths,
  marketingAuth: MarketingAuth.paths,
  marketing: Marketing.paths,
  decide: Decide.paths,
  products: Products.paths,
  socialNative: SocialNative.paths,
  stores: Stores.paths,
  utilities: Utilities.paths,
  wishlists: Wishlists.paths,
}
export const mockDataPaths = {
  authentication: Authentication.mockDataPaths,
  cart: Cart.mockDataPaths,
  consumer: Consumer.mockDataPaths,
  contactUs: ContactUs.mockDataPaths,
  content: Content.mockDataPaths,
  internal: Internal.mockDataPaths,
  marketingAuth: MarketingAuth.mockDataPaths,
  marketing: Marketing.mockDataPaths,
  decide: Decide.mockDataPaths,
  products: Products.mockDataPaths,
  socialNative: SocialNative.mockDataPaths,
  stores: Stores.mockDataPaths,
  utilities: Utilities.mockDataPaths,
  wishlists: Wishlists.mockDataPaths,
}
export const mockedApiPaths = {
  authentication: Authentication.mockedPaths,
  cart: Cart.mockedPaths,
  consumer: Consumer.mockedPaths,
  contactUs: ContactUs.mockedPaths,
  content: Content.mockedPaths,
  internal: Internal.mockedPaths,
  marketingAuth: MarketingAuth.mockedPaths,
  marketing: Marketing.mockedPaths,
  decide: Decide.mockedPaths,
  products: Products.mockedPaths,
  socialNative: SocialNative.mockedPaths,
  stores: Stores.mockedPaths,
  utilities: Utilities.mockedPaths,
  wishlists: Wishlists.mockedPaths,
}
