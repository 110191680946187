import type { PhoneCode } from '#types/config/phoneCode'

/**
 * @param fullPhone - phone number that may or may not include the country code
 * @returns a tuple with the format [country code, phone number, phone mask]. Country code defaults to 1 if not found
 */
export const parseE164Phone = (fullPhone: string): [string, string, string] => {
  // IMPORTANT: there are some libraries that solves this but range from 150KB to 500KB. Probably too much ATM
  // https://github.com/google/libphonenumber
  // https://gitlab.com/catamphetamine/libphonenumber-js#readme

  // remove all non numeric characters
  fullPhone = (fullPhone || '').replace(/\D/g, '') // though typed as 'string', fullPhone can be undefined when data comes from the API
  if (!fullPhone) return ['', '', '']

  const phoneCodes = useRuntimeConfig().public.phoneCodes as PhoneCode[]
  const fallbackMask = useRegion() === 'NORA' ? '(XXX) XXX - XXXX' : 'XXXX - XXX - XXX'
  // try to find a suitable country code from the beginning of the phone, and then matching the phone length
  // if we found matches, return the first one (we should find only one)
  // this should work for all EMEA and NORA well formatted phones, as they have a fixed length declared in phoneCodes.json (length is calculated from their masks)
  const dialCodeMatches: [string, string, string][] = []
  let fullMatch = { dialCode: '', phoneNumber: '', mask: '' }
  for (const { dialCode, mask } of phoneCodes) {
    if (!fullPhone.startsWith(dialCode)) continue
    const phoneNumber = fullPhone.substring(dialCode.length)
    const maskMatch = !Array.isArray(mask)
      ? mask || fallbackMask
      : (mask.find((value) => value.match(/X/g)?.length === phoneNumber.length) || fallbackMask)
    dialCodeMatches.push([dialCode, phoneNumber, maskMatch])
    const targetPhoneLength = maskMatch ? maskMatch.match(/X/g)?.length : 10
    if (targetPhoneLength === phoneNumber.length) {
      fullMatch = { dialCode, phoneNumber, mask: maskMatch || fallbackMask }
      break
    }
  }
  if (fullMatch.dialCode) return Object.values(fullMatch) as [string, string, string]

  // At this point we either have:
  // 1. a phone missing the phone code
  // 2. a phone from a different region
  // 3. or an incomplete / invalid phone

  // 1. rule out the most probably use cases, phones from US and CA missing the country code
  // if fullPhone is just 10 digits, assume the phone wasn't saved with country code but is from CA or US
  // this would be the case if the phone was ingested without asking for the country prefix.
  // it could be also a valid phone from other country, but in case of ambiguity we return the most probable one
  if (fullPhone.length === 10) return ['1', fullPhone, fallbackMask]

  // 2. try to find a suitable country code from the beginning of the phone
  if (dialCodeMatches.length) return dialCodeMatches[0]

  // 3. if we couldn't find a suitable country code, assume the phone is from US but the phone is incomplete or invalid
  return ['1', fullPhone, fallbackMask]
}
