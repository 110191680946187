import type { ModulesConfig } from '#types/mappings'

const modules: ModulesConfig = {
  CMPicture: {
    component: 'picture',
    hydration: 'never'
  },
  CMVideo: {
    component: 'video',
    hydration: 'visible'
  },
  P13NExperience: {
    component: 'experience',
    hydration: 'visible'
  },
  P13NSegmentation: {
    component: 'segmentation',
    hydration: 'visible'
  },
  VfCanvasAccordions: {
    component: 'accordions',
    hydration: 'visible'
  },
  VfCanvasAnchor: {
    component: 'anchor'
  },
  VfCanvasArticleGrid: {
    component: 'article-grid',
    hydration: 'visible'
  },
  VfCanvasBanner: {
    variants: {
      imageBanner: {
        component: 'banner-image'
      },
      splitBanner: {
        component: 'banner-split'
      },
      thinBanner: {
        component: 'banner-thin'
      }
    },
    hydration: 'visible'
  },
  VfCanvasBannerCarousel: {
    component: 'collection',
  },
  VfCanvasBenefitBar: {
    component: 'benefit-bar',
    hydration: 'visible'
  },
  VfCanvasCTAButton: {
    component: 'cta-button',
    hydration: 'never'
  },
  VfCanvasCTAButtonCollection: {
    component: 'cta-button-collection',
  },
  VfCanvasCategoryCard: {
    component: 'category-card',
    hydration: 'never'
  },
  VfCanvasCategoryCardCollection: {
    component: 'collection',
  },
  VfCanvasDialog: {
    component: 'dialog'
  },
  VfCanvasEspotBanner: {
    component: 'banner-espot',
    hydration: 'visible'
  },
  VfCanvasFooterItem: {
    component: 'footer-item',
    hydration: 'visible'
  },
  VfCanvasHero: {
    component: 'hero',
    hydration: 'visible'
  },
  VfCanvasIconAndTextCollection: {
    component: 'icon-and-text-collection',
    hydration: 'interaction'
  },
  VfCanvasInlineGrid: {
    component: 'inline-catalog-grid',
    hydration: 'visible'
  },
  VfCanvasLinkedCard: {
    component: 'linked-card',
    hydration: 'visible'
  },
  VfCanvasLinkedCardCollection: {
    component: 'collection',
  },
  VfCanvasMegaMenu: {
    component: 'mega-menu',
    hydration: 'visible'
  },
  VfCanvasMegaMenuBanner: {
    component: 'banner-menu',
    hydration: 'visible'
  },
  VfCanvasMegaMenuL1: {
    component: 'mega-menu-l1',
    hydration: 'visible'
  },
  VfCanvasMegaMenuL2: {
    component: 'mega-menu-l2',
    hydration: 'visible'
  },
  VfCanvasMegaMenuL3: {
    component: 'mega-menu-l3',
    hydration: 'visible'
  },
  VfCanvasMegaMenuMobile: {
    component: 'mega-menu-mobile',
    hydration: 'visible'
  },
  VfCanvasMegaMenuVisualSelector: {
    component: 'mega-menu-visual-selector',
    hydration: 'visible'
  },
  VfCanvasMultilinkCard: {
    component: 'multi-link-card',
    hydration: 'visible'
  },
  VfCanvasMultilinkCollection: {
    component: 'collection',
    hydration: 'visible'
  },
  VfCanvasPopUp: {
    component: 'pop-up',
    hydration: 'interaction'
  },
  VfCanvasProductRecommendations: {
    component: 'product-recommendations',
    hydration: 'visible'
  },
  VfCanvasProductUpsell: {
    component: 'product-upsell',
    hydration: 'visible'
  },
  VfCanvasPromoBar: {
    component: 'promo-bar',
    hydration: 'visible'
  },
  VfCanvasPromoBarMessage: {
    component: 'promo-bar-message',
    hydration: 'visible'
  },
  VfCanvasRichText: {
    component: 'rich-text',
    hydration: 'never'
  },
  VfCanvasSecondaryNav: {
    component: 'secondary-nav',
    hydration: 'visible'
  },
  VfCanvasUgc: {
    component: 'ugc',
    hydration: 'visible'
  },
  VfCanvasVideoPlayer: {
    component: 'video-player',
    hydration: 'visible'
  }
}

export default modules
