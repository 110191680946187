<template>
  <base-popover
    v-slot="{ open, close, opened, toggle }"
    ref="popover"
    class="h-full flex"
    :class="brandClasses.popover"
    :offset="1"
    strategy="fixed"
    close-on-leave
    @update:model-value="menuOpened = $event"
  >
    <div class="relative h-10 flex">
      <base-link
        :id="contentId"
        :to="content.target?.url"
        :target="content.target?.targetAttribute"
        class="h-full flex items-center gap-x-1 ws-nowrap"
        :class="brandClasses.link"
        :style="{ 'outline-offset': '-1px' }"
        :aria-expanded="opened"
        aria-haspopup="menu"
        @keydown.esc="close"
        @mouseover="e => hasSubItem ? open(e) : ''"
        @click="$emit('click', content.title)"
      >
        <vf-icon
          v-if="content.menuIcon"
          :src="`/img/logos/${brand}/${content.menuIcon.icon}.svg`"
          class="pointer-events-none"
          style="width: 1.625rem"
        />
        <span
          class="pointer-events-none relative"
          :class="[brandClasses.title, { 'sr-only': content.menuIcon && !content.titleVisibility }]"
          :style="[
            fontOffset && $os === 'win' && { 'margin-top': fontOffset },
            content.titleVisibility === 'title+icon' && { order: -1 },
          ]"
        >
          {{ content.title }}
          <span
            v-if="underlineOffset"
            :class="hasSubItem || suggestionsOpened ? 'b-grey-10' : 'b-white'"
            class="absolute inset-x-0 bottom-0 scale-x-0 b-b-3 ease-out duration group-hover:scale-x-full"
            :style="`margin-bottom: ${underlineOffset}; transform-origin: bottom left;`"
          />
        </span>
      </base-link>
      <base-button
        class="absolute inset-y-0 right-0 w-0 overflow-hidden focus:w-3"
        :style="{ 'outline-offset': '-1px' }"
        :aria-expanded="opened"
        aria-haspopup="menu"
        :aria-label="replaceAll($t.megaMenuToggle, { category: content.title })"
        @keydown.esc="close"
        @click="toggle"
      >
        <vf-icon name="chevron" size="xs" :dir="opened ? 'up' : 'down'" />
      </base-button>
    </div>
    <lazy-base-popover-content
      enter-from-class="op-0"
      enter-active-class="transform ease-out"
      leave-active-class="transform ease-in"
      leave-to-class="op-0"
      class="w-full bg-white c-grey-10 transition"
      style="z-index: 10000;"
      @keydown.esc="close"
    >
      <span class="absolute-0 bg-white" />
      <div
        role="menu"
        :aria-labelledby="contentId"
        class="relative z-1 bg-white pt-6 container"
        data-test-id="vf-mega-menu"
      >
        <div class="flex between">
          <vf-link
            :to="content.target?.url"
            :target="content.target?.targetAttribute"
            variant="quieter"
            class="flex items-center gap-2"
            :class="brandClasses.shopAll"
            @click="$emit('click', content.overrideShopAll || content.title)"
          >
            {{ content.overrideShopAll || `${$t.megaMenuShopAll} ${content.title}` }}
            <vf-icon :name="linkIcon" size="md" />
          </vf-link>
          <base-button :aria-label="$t.close" @click="close">
            <vf-icon name="close" :size="closeIconSize" />
          </base-button>
        </div>
        <div v-if="content.columns?.length" :class="brandClasses.columns" class="flex gap-4">
          <div
            v-for="({ children }, i) in content.columns"
            :key="i"
            :class="brandClasses.column"
            class="w-1/6"
          >
            <cms-section
              v-for="menuItem in children"
              :key="menuItem.id"
              :section="{ name: menuItem.id, items: [{ ...menuItem, isFullWidth: true }] }"
            />
          </div>
          <div class="ml-a w-1/6">
            <cms-section
              v-if="content.menuBanner"
              :section="{ name: 'menuBanner', items: [{ ...content.menuBanner, isFullWidth: true }] }"
            />
          </div>
        </div>
        <div
          v-if="content.visualSelectorsTitle"
          class="relative mx-2 text-center"
          :class="brandClasses.visualSelectorsTitleWrapper"
        >
          <div class="absolute inset-x-0 inset-y-1/2 b-t b-grey-70" />
          <p class="relative inline bg-white px-6" :class="brandClasses.visualSelectorsTitle">
            {{ content.visualSelectorsTitle }}
          </p>
        </div>
        <ul
          v-if="!isString(content.visualSelectors) && content.visualSelectors?.length"
          class="flex justify-center px-2"
          :class="brandClasses.visualSelectors"
          data-test-id="vf-mega-menu-visuals"
        >
          <li v-for="visualSelector in content.visualSelectors" :key="visualSelector.id">
            <cms-section
              :section="{
                name: visualSelector.id,
                items: [{ ...visualSelector, isFullWidth: true }],
              }"
            />
          </li>
        </ul>
      </div>
      <div class="h-6 shadow-lg" tabindex="0" @focus="close" />
    </lazy-base-popover-content>
  </base-popover>
</template>

<script lang="ts" setup>
import type { BasePopover } from '#components'
import type { MenuItem } from '#types/components/cms/mega-menu'
import { HeaderContextKey } from '#commerce/components/vf/header/context'

const { content } = defineProps<{
  content: MenuItem
}>()

defineEmits<{
  click: [label: string]
}>()

const {
  megaMenu: { linkIcon, closeIconSize },
  megaMenuL1Desktop: { brandClasses, fontOffset, underlineOffset }
} = useAppConfig().components.cms

const { brand } = useRuntimeConfig().public
const { $os } = useNuxtApp()
const router = useRouter()
const popover = ref<InstanceType<typeof BasePopover>>()
const contentId = `mega-menu-l1-${content.id}`

const { menuOpened, suggestionsOpened } = inject(HeaderContextKey)!

// simplify when bugfix https://digital.vfc.com/jira/browse/GLOBAL15-82870 will be done
const hasSubItem = content.columns?.some((column) => Object.keys(column).length)
  || content.menuBanner
  || content.visualSelectors?.length

router.beforeEach(() => {
  if (popover.value?.opened) popover.value.close()
})
</script>
