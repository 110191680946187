import type { Content } from '#types/content'
import type { ModulesConfig } from '#types/mappings'

export const resolveContentComponent = (content: Content, mappings: ModulesConfig) => {
  const config = mappings[content.type]

  if (!config)
    return

  let { hydration, component, variants } = config

  if (variants) {
    const variant = variants[content.variant!]
    component = variant.component
    hydration = variant.hydration || hydration
  }

  return { ...content, component, hydration }
}
