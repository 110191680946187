import type { InjectionKey } from 'vue'
import { getVideoForBreakpoint } from '#content/utils'
import type { Content, ResponsiveMedia } from '#types/content'
import type { PageContent } from '#types/page'
import type { ContentPage, PageChildrenData } from '#root/api/clients/content/data-contracts'
import type { Content as ContentNamespace } from '#root/api/clients/content/ContentRoute'
import type { LocaleCode } from '#types/locale'
import type { UseCms } from '#types/composables/useCms'

export const LazyMedia: InjectionKey<boolean | undefined> = Symbol('LazyMediaFlag')

export const useCms = (): UseCms => {
  const { brand, cmsApiRoutePrefix, isDev } = useRuntimeConfig().public
  const { exclusionList } = useAppConfig().pages.cms
  const { cmsSiteId } = useAppConfig().api
  const { $t, $seo, $viewport } = useNuxtApp()
  const { content: contentApi } = useApi()
  const route = useRoute()
  const host = useHost()
  const {
    enableFContentProxy,
    configApiDomains
  } = useFeatureFlags()

  const cmsBaseUrl = (import.meta.server && configApiDomains.cmsCutoverUrlSSR) || configApiDomains.cmsBaseUrl

  const locale = route.query.localePath as LocaleCode || useLocale()
  const siteId = computed(() => route.query.siteId as string || `${cmsSiteId || brand}-${locale}`)

  // Preview mode is defined once per application loading. To change the mode, the application must be reloaded.
  const isPreview = route.query.preview === 'true'
  const viewMode = isPreview ? 'preview' : 'publish'

  const baseUrl = `${(isDev || enableFContentProxy) ? cmsApiRoutePrefix : cmsBaseUrl}/${viewMode}`

  const getMediaUrl = (url: string | undefined): string => {
    if (!url) return ''
    const isExternalLink = url.startsWith('http')

    return isExternalLink ? url : `${baseUrl}${url}`
  }

  const getMedia = (media: ResponsiveMedia): any => {
    return getResponsiveMedia(getMediaUrl, media)
  }

  const getVideo = (video: any) => {
    return getVideoForBreakpoint(video, $viewport)
  }

  const getSeoMetadata = (page: PageContent) => {
    const { title, name, metadata } = page
    const countryCode = locale.split('-')[1]

    const {
      htmlTitle,
      htmlDescription,
      ogTitle,
      ogDescription,
      ogImage,
      canonical,
      robots
    } = metadata

    const seoFallbackPlaceholderValues = {
      brand: $t.brand,
      brandName: $t.brandName,
      pageName: name || ''
    }

    const fallbackTitle = replaceAll($seo.page.title, seoFallbackPlaceholderValues)
    const fallbackDescription = replaceAll($seo.page.description, seoFallbackPlaceholderValues)
    const image = (ogImage && getMediaUrl(ogImage[0]?.url)) || `/img/logos/${brand}/default.svg`

    return {
      metadata: {
        title: htmlTitle || title || fallbackTitle,
        description: htmlDescription || fallbackDescription,
        ogImage: image,
        ogType: 'website',
        ogSiteName: `${brand}-${countryCode}`,
        twitterCard: 'summary',
        twitterImage: image,
        twitterSite: `@${brand}`,
        ...(ogTitle && { ogTitle }),
        ...(ogDescription && { ogDescription }),
        ...(robots && { robots })
      },
      link: [
        {
          rel: 'canonical',
          href: canonical
        }
      ]
    }
  }

  const getFooter = async () => {
    const { data, error } = await contentApi.footer(viewMode, siteId.value)

    if (error.value) return

    return data.value?.content
  }

  const getFragment = async (id: string): Promise<Content | undefined> => {
    const { data, error } = await contentApi.fragment(viewMode, id)

    if (error.value) {
      showError({
        statusCode: error.value.statusCode,
        statusMessage: error.value.message,
        data: {
          path: error.value.data?.path,
          host,
        }
      })
    }

    return data.value?.content
  }

  const getMegaMenu = async () => {
    let megamenuContent

    try {
      const { data, error } = await contentApi.menu(viewMode, siteId.value)

      if (!error.value) megamenuContent = data.value?.content
    }
    catch (e) {
      if (!(import.meta.server))
        throw e
    }

    if (import.meta.server) {
      const megaMenuKey = `megamenu-${viewMode}-${siteId.value}`

      if (!megamenuContent) {
        const response = await $fetch(`/fapi/cached-data/${megaMenuKey}`)
        megamenuContent = response
      }
      else {
        await $fetch(`/fapi/cached-data/${megaMenuKey}`, { method: 'POST', body: megamenuContent })
      }
    }

    return megamenuContent
  }

  const getPage = async (slug?: string | string[]): Promise<PageContent | undefined> => {
    let url = ''

    if (slug) {
      const strSlug = Array.isArray(slug) ? slug.join('/') : slug
      if (exclusionList.find((item) => strSlug.includes(item))) return
      url += `/${strSlug}`
    }

    const { data, error } = await contentApi.page(viewMode, siteId.value, url)

    if (error.value) {
      showError({
        statusCode: error.value.statusCode,
        statusMessage: error.value.message,
        data: {
          host,
          path: error.value.data?.path || url,
        }
      })
      return
    }

    const content = data.value?.content || {} as ContentPage

    return {
      ...content,
      padding: content.mainContentPadding,
      sections: (content.sections || []).reduce((acc, { name, items = [] }) => ({
        ...acc,
        [name]: { name, items, lazy: name.includes('lazy') }
      }), {})
    }
  }

  const getPopUps = async () => {
    // Revert back after https://github.com/nuxt/nuxt/issues/23349 will be resolved
    const data = await contentApi.$popups(viewMode, siteId.value)
    return data.content
  }

  // Remove and replace with getFragment after https://github.com/nuxt/nuxt/issues/23349 will be resolved
  const getModal = async <T extends Content>(id: string): Promise<T> => {
    const data = await contentApi.$fragment(viewMode, id) as any
    return data.content
  }

  const getPageChildren = async (parentPageSlug: string): Promise<PageChildrenData['content']> => {
    const data = await contentApi.$pageChildren(viewMode, siteId.value, parentPageSlug)
    return data.content
  }

  const getSections = {
    account: () => contentApi.espotsAccount(viewMode, siteId.value),
    accountProfile: () => contentApi.espotsAccountProfile(viewMode, siteId.value),
    cart: () => contentApi.espotsCart(viewMode, siteId.value),
    category: (categoryId: string | Ref<string>) => contentApi.espotsCategory(viewMode, siteId.value, categoryId),
    checkout: () => contentApi.espotsCheckout(viewMode, siteId.value),
    contactUs: () => contentApi.espotsContactUs(viewMode, siteId.value),
    error: () => contentApi.espotsError4Xx(viewMode, siteId.value),
    giftCard: () => contentApi.espotsGiftCard(viewMode, siteId.value),
    loyaltyModal: () => contentApi.espotsLoyaltyModal(viewMode, siteId.value),
    orderConfirmation: () => contentApi.espotsOrderConfirmation(viewMode, siteId.value),
    product: (productId: string | Ref<string>) => contentApi.espotsProduct(viewMode, siteId.value, productId),
    promotions: () => contentApi.espotsPromotion(viewMode, siteId.value),
    search: () => contentApi.espotsSearch(viewMode, siteId.value),
    signUp: () => contentApi.espotsSignUp(viewMode, siteId.value),
    signUpModal: () => contentApi.espotsSignUpModal(viewMode, siteId.value),
    $signUpModal: () => contentApi.$espotsSignUpModal(viewMode, siteId.value),
    storeLocator: () => contentApi.espotsStoreLocator(viewMode, siteId.value)
  }

  const getSearch = async (
    searchType: ContentNamespace.Search.RequestParams['searchType'],
    query: ContentNamespace.Search.RequestQuery
  ) => {
    const data = await contentApi.$search(viewMode, siteId.value, searchType, query)

    return data?.content
  }

  return {
    getFooter,
    getFragment,
    getMedia,
    getMediaUrl,
    getMegaMenu,
    getModal,
    getPage,
    getPageChildren,
    getPopUps,
    getSections,
    getSeoMetadata,
    getVideo,
    isPreview,
    getSearch,
    siteId
  }
}
