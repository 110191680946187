<template>
  <div class="space-y-4">
    <vf-link
      v-if="content.title"
      :to="content.target?.url"
      :target="content.target?.targetAttribute"
      variant="quieter"
      class="i-block "
      @click="$emit('click', `${l1} - ${content.title}`)"
    >
      {{ content.title }}
    </vf-link>
    <ul v-if="content.children?.length" class="text-sm space-y-2">
      <li v-for="menuItem in content.children" :key="menuItem.id">
        <cms-section
          :section="{
            name: menuItem.id,
            items: [{ ...menuItem, menuStyle: content.menuStyle, isFullWidth: true } as MenuItem],
          }"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { MegaMenuL1ContextKey } from './context'
import type { MenuItem } from '#types/components/cms/mega-menu'

defineProps<{
  content: MenuItem
}>()

defineEmits<{
  click: [label: string]
}>()

const l1 = inject(MegaMenuL1ContextKey)
</script>
