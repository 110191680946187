/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type { GetTokenData, MarketingTokenRequest } from "./data-contracts";

export class MarketingAuth extends HttpClient {
  static paths = { getToken: "/v2/token" };
  static mockDataPaths = {};
  static mockedPaths = { getToken: false };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "marketing-auth");
  }

  /**
   * No description
   *
   * @tags MarketingAuth
   * @name GetToken
   * @summary SFMC Authentication Token
   * @request POST:/v2/token
   */
  getToken = <ResT = GetTokenData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: MarketingTokenRequest,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/v2/token")
        ? computed(() => mockPath("MarketingAuth", "getToken", ``))
        : computed(() => `/v2/token`),
      method: "POST",
      body: data,
      key: "/v2/token",
      apiName: "MarketingAuth.getToken",
      ...params,
    });
  /**
   * No description
   *
   * @tags MarketingAuth
   * @name GetToken
   * @summary SFMC Authentication Token
   * @request POST:/v2/token
   */
  $getToken = (data: MarketingTokenRequest, requestParams: ApiOptions = {}) =>
    this.$request<GetTokenData>({
      path: isMocked("/v2/token") ? mockPath("MarketingAuth", "getToken", ``) : `/v2/token`,
      method: "POST",
      body: data,
      apiName: "MarketingAuth.getToken",
      ...requestParams,
    });
}
