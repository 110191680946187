import apiClientFactory from '#root/api'
import { applyApiProxy } from '#root/api/util/proxying'
import type { CacheHeaders } from '#types/plugin/cache'

/**
 * Retrieves headers for API requests.
 * @returns The headers object for API requests.
 * @category Utils
 */
export const useApiHeaders = () => {
  const { brandPrefix, clientApp } = useAppConfig().api
  return getApiHeaders(brandPrefix, clientApp, useCountryCode())
}

const getContentQuery = (env: string, target: string) => {
  const contentTarget = env !== 'PROD' && target
  return contentTarget ? { cms: contentTarget } : {} as any
}

const setupHeaders = () => {
  const locale = useLocale()
  const settings = useApiHeaders() || {}
  const correlationId = uuid()
  const { enableFApiProxy } = useFeatureFlags()
  const reqHeaders = useRequestHeaders()
  const { authAccessKey, ssrTrustId } = useRuntimeConfig().api || {}
  const { isDev } = useRuntimeConfig().public

  return {
    ...settings,
    'Accept': 'application/json',
    'x-transaction-id': correlationId,
    'x-correlation-id': correlationId,
    'x-auth-access': authAccessKey,
    'vf-server-trust-id': ssrTrustId,
    'locale': (isDev || enableFApiProxy) ? locale : getApiLocale(locale),
    ...(reqHeaders?.['bg-testing'] && { 'bg-testing': reqHeaders['bg-testing'] }),
    ...(isDev && reqHeaders?.['x-disable-human'] ? { 'x-disable-human': reqHeaders['x-disable-human'] } : {}),
  } as Record<string, string>
}

const updateHeaders = (options) => {
  if (options.headers) {
    if (!useCookie('x-usid').value)
      delete options.headers['x-usid']

    if (options.headers['x-transaction-id']) {
      const id = uuid()
      options.headers['x-transaction-id'] = id
      options.headers['x-correlation-id'] = id
    }
  }
}

export const useApi = () => {
  const locale = useLocale()
  const route = useRoute()
  const { enableFApiProxy, enableFContentProxy, configApiDomains = { apiCutoverUrlSSR: undefined, cmsCutoverUrlSSR: undefined, apiBaseUrl: '', cmsBaseUrl: '' } } = useFeatureFlags()
  const isEmea = useRegion() === 'EMEA'
  const host = useHost()

  const apiBaseUrl = (import.meta.server && configApiDomains.apiCutoverUrlSSR) || configApiDomains.apiBaseUrl
  const cmsBaseUrl = (import.meta.server && configApiDomains.cmsCutoverUrlSSR) || configApiDomains.cmsBaseUrl

  const reqHeaders = useRequestHeaders()

  const bypassHeaderName = 'x-bypass-maintenance'
  const bypassHeader = reqHeaders[bypassHeaderName]

  const { apiRoutePrefix, cmsApiRoutePrefix, isDev, targetEnv } = useRuntimeConfig().public

  const monetateBaseUrl = host.includes('localhost') || isEmea ? 'https://engine.monetate.net' : '/decision'

  const { baseAuthURL, baseRestURL } = useAppConfig().api?.sfmc || {}

  const apiHeaders = setupHeaders()

  const usidHeader = {
    // the x-usid cookie value is set on the Auth middleware
    // and cleared on sign-out on the Auth Store
    'x-usid': useCookie('x-usid').value || uuid()
  }

  // This is an object where every key from the API settings has an empty string value
  // and can be used to override the base headers where needed
  // (e.g., Monetate breaks if a locale header is sent)
  const clearedApiHeaders = Object.entries(apiHeaders).reduce((acc, [key]) => ({ ...acc, [key]: '' }), {})

  const contentPreviewDate = route.query.previewDateRFC1123 as string

  const cacheHeaders = useState<CacheHeaders>('cacheHeaders')

  return apiClientFactory({
    base: {
      baseURL: (isDev || enableFApiProxy) ? apiRoutePrefix : apiBaseUrl,
      headers: {
        ...apiHeaders,
        cookie: useRequestHeaders().cookie || ''
      },
      query: {
        locale,
        ...(route.query.bypassAllCache === 'true' && { bypassAllCache: true })
      },
      onRequest({ options }) {
        updateHeaders(options)
      }
    },
    authentication: {
      headers: usidHeader
    },
    content: {
      baseURL: (isDev || enableFContentProxy) ? cmsApiRoutePrefix : cmsBaseUrl,
      query: {
        ...getContentQuery(targetEnv, route.query.cms as string),
        removeNulls: true
      },
      headers: {
        ...apiHeaders,
        ...(bypassHeader && { [bypassHeaderName]: bypassHeader }),
        ...(contentPreviewDate ? { 'X-Preview-Date': contentPreviewDate } : {}),
        'CoreMedia-PreviewAccessToken': 'FirstCoreMediaSandBox'
      },
      onResponse(responseData) {
        // This callback is attached to CMS endpoints only
        // Save cache headers
        if (import.meta.server) {
          const cacheTags = ['Netlify-Cache-Tag', 'Edge-Cache-Tag', 'Netlify-CDN-Cache-Control', 'Akamai-Cache-Control']

          cacheTags.forEach((tag) => {
            const cacheHeader = responseData.response.headers.get(tag)

            if (cacheHeader)
              cacheHeaders.value[tag].push(cacheHeader)
          })
        }
      }
    },
    consumer: {
      headers: usidHeader
    },
    internal: {
      baseURL: apiRoutePrefix
    },
    marketingAuth: applyApiProxy({
      baseURL: baseAuthURL,
      query: {
        locale: ''
      }
    }),
    marketing: applyApiProxy({
      baseURL: baseRestURL,
      query: {
        locale: ''
      }
    }),
    wishlists: {
      headers: {
        ...usidHeader,
        // This is required for removing an item from the wishlist,
        // the API requires a content type, but the DELETE request has no body
        // so the client can't know to set it automatically
        'content-type': 'application/json'
      }
    },
    decide: {
      baseURL: monetateBaseUrl,
      headers: {
        ...clearedApiHeaders,
        Accept: 'application/json',
      }
    },
    socialNative: {
      baseURL: 'https://photorankapi-a.akamaihd.net',
      headers: {
        ...clearedApiHeaders,
        Accept: 'application/json',
      }
    }
  })
}
