/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { assignDeep } from "#core/utils/object/mergeDeep";
import { apiResponseErrorHandler } from "#root/api/util/error";
import type { NitroFetchOptions } from "nitropack";
import type { FetchContext } from "ofetch";
import { parseQuery, parseURL, stringifyParsedURL } from "ufo";
import { randomUUID as uuid } from "uncrypto";
import { computed, markRaw, reactive, toValue } from "vue";

import type { UseFetchOptions } from "#app";
import type { ApiLogParams } from "#root/api/util/logging";
import {
  addRequest,
  handleRequestStartForLogging,
  logRequestError,
  logResponseError,
  logResponseSuccess,
} from "#root/api/util/logging";
import { isMockPath } from "#root/api/util/mocking";
import type { ValidationResult } from "#root/api/util/validation";

export type PathType = string | Ref<string> | ComputedRef<string>;

export type ApiOptions = NitroFetchOptions<string> & {
  path?: PathType;
  apiName?: string;
  query?: object;
  validate?: boolean;
};

export type UseApiOptions<T, R = T, K extends KeysOf<R> = KeysOf<R>, D = null> = UseFetchOptions<T, R, K, D> & {
  path?: PathType;
  apiName?: string;
  _query?: any;
  validate?: boolean;
};

export type Response<T = {}, E = {}> = ReturnType<typeof useFetch<T, E>>;

type MaybeValidated<T> = T & {
  validationResult?: ValidationResult;
};

export class HttpClient {
  protected baseParams = {};
  protected contractName: string;

  constructor(baseParams, contractName) {
    this.baseParams = baseParams;
    this.contractName = contractName;
  }

  protected getPath(path?: PathType) {
    return isReadonly(path) ? (path as ComputedRef<string>).value : unref(path as Ref<string>) || "";
  }

  // Custom query encoding
  // https://github.com/unjs/ufo/issues/165#issuecomment-1934100006
  protected encodeQuery(ctx: FetchContext & { options: UseApiOptions<any> }) {
    if (!ctx.options._query) return;

    const url = parseURL(ctx.request.toString());
    const query = new URLSearchParams();

    Object.entries({
      ...parseQuery(url.search),
      ...toValue(ctx.options._query),
    }).forEach(([key, value]) => {
      if (value !== undefined && value !== null) query.append(key, toValue(value) as string);
    });

    url.search = query.toString();
    ctx.request = stringifyParsedURL(url);
  }

  protected prepareOptions(options: ApiOptions, isComposable?: boolean): ApiOptions;
  protected prepareOptions<T, R, K extends KeysOf<R>, D>(
    options: UseApiOptions<T, R, K, D>,
    isComposable?: boolean,
  ): UseApiOptions<T, R, K, D>;
  protected prepareOptions<T, R, K extends KeysOf<R>, D>(
    options: ApiOptions | UseApiOptions<T, R, K, D>,
    isComposable?: boolean,
  ): ApiOptions | UseApiOptions<T, R, K, D> {
    const { $dtrum, $t } = useNuxtApp();
    const { serverRequestTimeout } = useRuntimeConfig().public;

    if (isMockPath(this.getPath(options.path))) options.baseURL = "/fapi";

    Object.entries(this.baseParams).forEach(([key, value]) => {
      if (options[key] && value && typeof value === "object") assignDeep(options[key], value);

      if (typeof options[key] === "function" && typeof value === "function") {
        const option = options[key];

        options[key] = function (...args) {
          value.apply(this, args);
          option.apply(this, args);
        };
      }

      if (!options[key]) options[key] = value;
    });

    if (process.server) options.signal = AbortSignal.timeout(Number(serverRequestTimeout));

    if (options.headers) {
      // Remove headers with empty values - this allows for clients to remove headers applied globally
      options.headers = Object.fromEntries(Object.entries(options.headers).filter(([_key, value]) => value));
      // Prevents headers from reactively re-executing requests
      // in order to allow third-parties to inject tracking values
      options.headers = markRaw(options.headers);
    }

    const isUseApiOptions = (opt: any): opt is UseApiOptions<T, R, K, D> => !!isComposable;

    if (isUseApiOptions(options)) {
      options._query = options.query ? reactive(options.query) : undefined;
      options.query = undefined;

      // Workaround for custom query handling
      if (options.watch !== false)
        options.watch = [...(Array.isArray(options.watch) ? options.watch : []), options._query];
    }

    const { apiName, onRequest, onRequestError, onResponse, onResponseError } = options;

    const logParams: ApiLogParams = {
      apiName,
      path: computed(() => this.getPath(options.path)),
      logKey: uuid(),
      logConfig: getLogConfig(),
      method: unref(options.method) || "get",
      params: unref(options.params) || {},
    };

    options.onRequest = (context) => {
      addRequest(
        {
          path: this.getPath(options.path),
          headers: toValue(options.headers) as any,
          baseURL: toValue(options.baseURL),
          method: toValue(options.method) || "get",
          query: toValue(isUseApiOptions(options) ? options._query : options.query),
        },
        process.server,
      );
      handleRequestStartForLogging(logParams);
      log.info(`Request: ${context.request} Headers: ${JSON.stringify(context.options.headers)}`);
      unref(onRequest as any)?.(context);
      // @ts-expect-error incorrect context type
      this.encodeQuery(context);
    };

    options.onRequestError = (context) => {
      logRequestError({ ...logParams, options: context.options, error: context.error });

      unref(onRequestError as any)?.(context);
    };

    options.onResponse = async (context) => {
      logResponseSuccess({ ...logParams, options: context.options, response: context.response });

      if (options.validate && typeof context.response._data === "object") {
        context.response._data.validationResult = await $fetch(`/fapi/api/validate-response/${this.contractName}`, {
          method: "post",
          body: {
            data: context.response._data,
            error: !!context.error,
            path: this.getPath(options.path),
            method: options.method || "get",
          },
        });
      }

      unref(onResponse as any)?.(context);
    };

    options.onResponseError = (context) => {
      logResponseError({ ...logParams, options: context.options, response: context.response, error: context.error });

      unref(onResponseError as any)?.(context);

      apiResponseErrorHandler(context, $t, $dtrum);
    };

    return options;
  }

  public request<T, R = T, K extends KeysOf<R> = KeysOf<R>, D = null>(
    options: UseApiOptions<MaybeValidated<T>, R, K, D>,
  ) {
    return useFetch(options.path || "", this.prepareOptions(options, true));
  }

  public $request<T>(options: ApiOptions) {
    return $fetch<MaybeValidated<T>>(this.getPath(options.path), this.prepareOptions(options));
  }
}
