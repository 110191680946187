/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type { FireInteractionEventData, MarketingEventsFireRequest } from "./data-contracts";

export class Marketing extends HttpClient {
  static paths = { fireInteractionEvent: "/interaction/v1/events" };
  static mockDataPaths = {};
  static mockedPaths = { fireInteractionEvent: false };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "marketing");
  }

  /**
   * No description
   *
   * @tags Marketing
   * @name FireInteractionEvent
   * @summary SFMC Fire Interaction Event
   * @request POST:/interaction/v1/events
   */
  fireInteractionEvent = <
    ResT = FireInteractionEventData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: MarketingEventsFireRequest,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/interaction/v1/events")
        ? computed(() => mockPath("Marketing", "fireInteractionEvent", ``))
        : computed(() => `/interaction/v1/events`),
      method: "POST",
      body: data,
      key: "/interaction/v1/events",
      apiName: "Marketing.fireInteractionEvent",
      ...params,
    });
  /**
   * No description
   *
   * @tags Marketing
   * @name FireInteractionEvent
   * @summary SFMC Fire Interaction Event
   * @request POST:/interaction/v1/events
   */
  $fireInteractionEvent = (data: MarketingEventsFireRequest, requestParams: ApiOptions = {}) =>
    this.$request<FireInteractionEventData>({
      path: isMocked("/interaction/v1/events")
        ? mockPath("Marketing", "fireInteractionEvent", ``)
        : `/interaction/v1/events`,
      method: "POST",
      body: data,
      apiName: "Marketing.fireInteractionEvent",
      ...requestParams,
    });
}
