<template>
  <div ref="elementRef" @click="handlePromotionClick">
    <div class="relative grid">
      <div class="relative grid-area-stack">
        <base-picture
          v-if="responsiveMedia.images"
          v-bind="responsiveMedia.images"
          :lazy
          fit="cover"
          class="full"
        />
        <base-video
          v-if="responsiveMedia.video"
          v-bind="video"
          ref="videoRef"
          :autoplay="!lazy"
          :controls="false"
          loop
          class="full cover"
        />
        <base-link
          v-if="mediaLink"
          :to="mediaLink.url"
          :target="mediaLink.targetAttribute"
          aria-hidden="true"
          tabindex="-1"
          class="absolute-0"
        />
      </div>
      <div
        class="relative flex md:pointer-events-none md:p-12 md:grid-area-stack"
        :class="[contentAlignmentSmall[overlaySmall], contentAlignmentLarge[overlayLarge]]"
      >
        <div
          v-style:bg="backdrop"
          class="space-y-2 md:p-6 md:-m-6 lg:space-y-4"
          :class="[
            overlayWidth && contentSize[overlayWidth],
            overlaySmall?.includes('overlay') ? 'p-2 -m-2' : '<md:bg-transparent',
          ]"
        >
          <h1 v-if="title" v-style:c="titleColor" :class="titleStyle">
            <base-link v-if="mediaLink" :to="mediaLink.url" :target="mediaLink.targetAttribute">
              {{ title }}
            </base-link>
            <template v-else>
              {{ title }}
            </template>
          </h1>
          <p v-if="subtitle" v-style:c="subtitleColor" :class="subtitleStyle">
            {{ subtitle }}
          </p>
          <cms-rich-text v-if="content.richText" v-style:c="subtitleColor" class="pointer-within" :content="{ richText: content.richText }" />
          <div
            v-if="linkType !== 'No-CTA' && targets.length"
            class="pointer-within gap-4 i-flex <md:hidden lg:gap-6 not-first:pt-4"
          >
            <div
              v-style="equalTargetStyle"
              class="gap-4 wrap lg:gap-6"
              :class="[ctaAlignmentLarge[overlayLarge], isEqualWidthButtons ? 'grid' : 'flex']"
            >
              <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="linkType !== 'No-CTA' && targets.length"
      v-style="equalTargetStyle"
      class="mt-6 f-col gap-4 px-4 lg:hidden md:hidden"
      :class="[equalTargetSize?.sm ? '' : ctaAlignmentSmall[overlaySmall]]"
    >
      <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey } from '#content/components/cms/section/context'
import type { HeroContent, OverlayLarge, OverlaySmall } from '#types/components/cms/hero'
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'

const { content } = defineProps<{
  content: HeroContent
}>()

const {
  backdrop,
  equalTargetSize,
  linkType,
  media,
  mediaTarget,
  overlayLarge,
  overlaySmall,
  overlayWidth,
  promotionTracking,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  title,
  titleColor,
  titleStyle
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia, getVideo } = useCms()
const {
  elementRef,
  handlePromotionClick
} = usePromotionTracking(promotionTracking, inject(SectionContextKey), content.name)

const lazy = inject(LazyMedia)
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)
const video = ref<BaseVideoPropType>({ src: '' })
const videoRef = ref<{ $el: HTMLVideoElement }>()

const contentAlignmentLarge: Record<OverlayLarge, string> = {
  'left-left-top': 'md:items-start',
  'left-left-middle': 'md:items-center',
  'left-left-bottom': 'md:items-end',
  'left-right-middle': 'md:justify-end md:items-center',
  'center-left-middle': 'md:text-center md:items-center',
  'center-center-top': 'md:text-center md:justify-center md:items-start',
  'center-center-middle': 'md:text-center md:center',
  'center-center-bottom': 'md:text-center md:justify-center md:items-end',
  'center-right-middle': 'md:text-center md:justify-end md:items-center'
}

const contentAlignmentSmall: Record<OverlaySmall, string> = {
  'left-overlay': '<md:grid-area-stack <md:pointer-events-none <md:items-center <md:p-4',
  'left-below': '<md:mt-6 <md:items-start <md:px-4',
  'center-overlay': '<md:grid-area-stack <md:pointer-events-none <md:center <md:p-4 <md:text-center',
  'center-below': '<md:mt-6 <md:center <md:px-4 <md:text-center'
}

const contentSize = {
  xs: 'w-10/12 md:w-1/3',
  sm: 'w-10/12 md:w-1/2',
  md: 'w-11/12 md:w-2/3',
  lg: 'w-11/12'
}

const ctaAlignmentLarge: Partial<Record<OverlayLarge, string>> = {
  'center-left-middle': 'md:justify-center',
  'center-center-top': 'md:justify-center',
  'center-center-middle': 'md:justify-center',
  'center-center-bottom': 'md:justify-center',
  'center-right-middle': 'md:justify-center'
}

const ctaAlignmentSmall: Record<OverlaySmall, string> = {
  'left-overlay': '<md:items-start',
  'left-below': '<md:items-start',
  'center-overlay': '<md:items-center',
  'center-below': '<md:items-center'
}

const isEqualWidthButtons = getValueForBreakpoint('sm', equalTargetSize)

const equalTargetStyle = {
  ...(isEqualWidthButtons && {
    'grid-cols': {
      sm: '1fr',
      md: `repeat(${targets.length}, minmax(0,1fr))`
    }
  })
}

onMounted(() => {
  if ('video' in responsiveMedia) {
    video.value = getVideo(responsiveMedia.video) as BaseVideoPropType
    if (videoRef.value) {
      videoRef.value.$el.load()
      videoRef.value.$el.play()
    }
  }
})
</script>
